import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
 
class P2019
 extends Component {
  render() {
    return (
      <div>
        <h2>2019</h2>
      </div>
    );
  }
}
 
export default P2019
;