import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
      <div>
        <img src="https://static1.squarespace.com/static/5ad4d6c7a2772c84c876a433/5d9bfe1d891ec13e8f7d9642/5d9bfe3c3dc97713cd08faa3/1570504266192/WEBSITE+COVER+PHOTO.TIF.png?format=2500w" alt="" />
      </div>
    );
  }
}

export default Home;