import React, { Component } from "react";
import VideoNav from "./VideoNav";
 
class Video
 extends Component {

  render() {
    return (
      <div>
        <VideoNav/>
      </div>
    );
  }
}
 
export default Video
;
