import React, { Component } from "react";
import PaintNav from "./PaintingsNav";

class Paintings
 extends Component {
  render() {
    return (
      <div>
        <PaintNav/>
      </div>
    );
  }
}
 
export default Paintings
;