import React, { Component } from "react";
import PaperNav from "./PaperNav";

class Paper
 extends Component {
  render() {
    return (
      <div>
        <PaperNav/>
      </div>
    );
  }
}
 
export default Paper
;